import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button, Icon } from 'components';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { formatCurrency } from 'utils/rifmFormatters';
import { Transaction } from './transactions.d';

interface TransactionProps {
  data: Transaction;
  onRefundButtonClick?: (id: string) => void;
}

const TransactionListItem: React.FC<TransactionProps> = ({ data, onRefundButtonClick }) => {
  const [open, setOpen] = useState<boolean>(false);
  const receiptText = data.charged ? data.receiptNumber : 'Pending';

  return (
    <StyledTransaction className={clsx('transaction', data.type, { open })}>
      <div className="transaction__info">
        <div className="transaction__info__item">
          <span className="transaction__info__item__title">Date</span>
          <span>{dayjs(data.date).format('M/DD/YYYY hh:mm A')}</span>
        </div>
        <div className="transaction__info__item" title={data.name}>
          <span className="transaction__info__item__title">Name</span>
          <span>
            {data.name}{' '}
            {data.refunded && (
              <span className="status">
                Refunded
                {data.refundedAmount &&
                  data.refundedAmount !== data.amount &&
                  `: ${formatCurrency(data.refundedAmount)}`}
              </span>
            )}
          </span>
        </div>
        <div className="transaction__info__item amount">
          <span className="transaction__info__item__title">Amount</span>
          {formatCurrency(data.amount)}
        </div>
        <div className="transaction__info__item">
          <span className="transaction__info__item__title">Transaction Fee</span>
          {formatCurrency(data.commission)}
        </div>
        <div className="transaction__info__item" title={data.payer}>
          <span className="transaction__info__item__title">Payer</span>
          {data.payer}
        </div>
        <div className={clsx("transaction__info__item", { pending: !data.charged })}>
          <span className="transaction__info__item__title">Receipt Number</span>
          {data.charged ? data.receiptNumber : 'Pending'}
        </div>
      </div>
      {open && (
        <div className="transaction__details">
          <div className="transaction__details__item">
            <h5>Contact Info</h5>
            <div className="contact-info">
              <Icon name="email" /> <a href={`mailto:${data.email}`}>{data.email}</a>
            </div>
            <div className="contact-info">
              <Icon name="phone" /> <a href={`tel:${data.phone}`}>{data.phone}</a>
            </div>
          </div>
          <div className="transaction__details__item">
            <h5>Comment</h5>
            <p>{data.comment}</p>
          </div>
          <div className="transaction__details__item">
            <h5>Addons:</h5>
            {data.addons.map(item => (
              <div className="addon" key={item}>
                {item}
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="transaction__buttons-container">
        <button className="more-info-button" onClick={() => setOpen(v => !v)}>
          {open ? 'Less info' : 'More info'} <Icon name="chevron" />
        </button>
        {open && data.type === 'incomes' && (
          <Button
            className="refund-button"
            secondary
            onClick={() => onRefundButtonClick?.(data.id)}>
            Refund
          </Button>
        )}
      </div>
    </StyledTransaction>
  );
};

export default React.memo(TransactionListItem);

const StyledTransaction = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.seashell};

  &:last-of-type {
    border-bottom: none;
  }

  &.incomes .amount {
    color: ${props => props.theme.colors.green};
  }
  &.expenses .amount {
    color: ${props => props.theme.colors.red};
  }
  &.refunded .amount {
    color: ${props => props.theme.colors.orange};
  }

  .transaction {
    &__info {
      cursor: pointer;

      &__item {
        display: flex;
        margin: 16px 0;
        &.pending {
          color: ${props => props.theme.colors.grayDark};
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        &__title {
          color: #000;
          width: 40%;
          color: ${props => props.theme.colors.grayDark};
          margin-right: 20px;
          flex-shrink: 0;
        }
      }
    }

    &__details {
      h5 {
        font-size: 12px;
        font-weight: normal;
        line-height: 16px;
        color: #8b8b8b;
        margin: 0 0 12px 0;
      }

      &__item {
        margin: 24px 0;
      }

      .addon,
      .contact-info {
        margin: 12px 0;
      }

      .contact-info {
        white-space: nowrap;
        a {
          text-decoration: none;
          color: ${props => props.theme.colors.mineShaft};
        }

        .icon {
          vertical-align: middle;
        }
      }
    }

    &__buttons-container {
      margin: 16px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .more-info-button {
    background: none;
    padding: 0;
    border: 0;
    color: ${props => props.theme.colors.red};
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;

    .icon {
      fill: ${props => props.theme.colors.red};
      vertical-align: middle;
    }
  }

  &.open .more-info-button .icon {
    transform: rotate(180deg);
  }
`;
