import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button, Icon } from 'components';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { formatCurrency } from 'utils/rifmFormatters';
import { Transaction } from './transactions.d';

interface TransactionProps {
  data: Transaction;
  onRefundButtonClick?: (id: string) => void;
}

const TransactionListItem: React.FC<TransactionProps> = ({ data, onRefundButtonClick }) => {
  const [open, setOpen] = useState<boolean>(false);
  const receiptText = data.charged ? data.receiptNumber : 'Pending';

  return (
    <StyledTransaction className={clsx('transaction', data.type, { open })}>
      <div onClick={() => setOpen(v => !v)} className="transaction__info">
        <div className="transaction__info__item">
          <Icon name="plus-mini" />
        </div>
        <div className="transaction__info__item">
          {dayjs(data.date).format('M/DD/YYYY hh:mm A')}
        </div>
        <div className="transaction__info__item" title={data.name}>
          {data.name}{' '}
          {data.refunded && (
            <span className="status">
              Refunded
              {data.refundedAmount &&
                data.refundedAmount !== data.amount &&
                `: ${formatCurrency(data.refundedAmount)}`}
            </span>
          )}
        </div>
        <div className="transaction__info__item amount" title={data.type}>
          {formatCurrency(data.amount)}
        </div>
        <div className="transaction__info__item">{formatCurrency(data.commission)}</div>
        <div className="transaction__info__item" title={data.payer}>
          {data.payer}
        </div>
        <div className={clsx("transaction__info__item", { pending: !data.charged })} title={receiptText}>
          {receiptText}
        </div>
      </div>
      {open && (
        <div className="transaction__details">
          <div className="transaction__details__item">
            <h5>Contact Info</h5>
            <div className="contact-info">
              <Icon name="email" /> <a href={`mailto:${data.email}`}>{data.email}</a>
            </div>
            <div className="contact-info">
              <Icon name="phone" /> <a href={`tel:${data.phone}`}>{data.phone}</a>
            </div>
          </div>
          <div className="transaction__details__item">
            <h5>Comment</h5>
            <p>{data.comment}</p>
          </div>
          <div className="transaction__details__item">
            <h5>Addons:</h5>
            {data.addons.map(item => (
              <div className="addon" key={item}>
                {item}
              </div>
            ))}
          </div>
          {data.type === 'incomes' && (
            <Button secondary onClick={() => onRefundButtonClick?.(data.id)}>
              Refund
            </Button>
          )}
        </div>
      )}
    </StyledTransaction>
  );
};

export default React.memo(TransactionListItem);

const StyledTransaction = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.seashell};

  &:last-of-type {
    border-bottom: none;
  }

  &.incomes .amount {
    color: ${props => props.theme.colors.green};
  }
  &.expenses .amount {
    color: ${props => props.theme.colors.red};
  }
  &.refunds .amount {
    color: ${props => props.theme.colors.orange};
  }

  .icon-plus-mini {
    transition: transform 300ms;
  }

  &.open {
    .icon-plus-mini {
      transform: rotate(45deg);
    }
  }

  .transaction {
    &__info {
      height: 56px;
      display: grid;
      grid-gap: 32px;
      grid-auto-flow: column;
      grid-template-columns: 10px 1fr 2fr 0.5fr 0.5fr 1fr 1fr;
      align-content: center;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }

      &__item {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &.pending {
          color: ${props => props.theme.colors.grayDark};
        }
      }
    }

    &__details {
      display: grid;
      grid-template-columns: 1fr 1.5fr 1fr auto;
      padding: 0 0 32px 42px;
      grid-gap: 60px;

      h5 {
        font-size: 12px;
        font-weight: normal;
        line-height: 16px;
        color: #8b8b8b;
        margin-top: 0;
      }

      .addon,
      .contact-info {
        margin: 12px 0;
      }

      .contact-info {
        white-space: nowrap;
        a {
          text-decoration: none;
          color: ${props => props.theme.colors.mineShaft};
        }

        .icon {
          vertical-align: middle;
        }
      }
    }
  }
`;
