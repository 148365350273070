import React, { useState, useMemo } from 'react';
import notify from 'notify';

import { tryGetFirstError } from 'utils/requests';
import { getIncomes } from 'api/billing';
import DoughnutChart from './DoughnutChart';

const labels = ['#62B120', '#D6F5BC'];
const titles = ['Package', 'Addons'];

const TotalIncome = () => {
  const [totalIncome, setTotalIncome] = useState<{
    totalPackages: number;
    totalAddons: number;
  }>({
    totalPackages: 0,
    totalAddons: 0
  });

  const fetchIncomes = async (dates) => {
    try {
      const { totalPackages, totalAddons } = await getIncomes(dates);
      setTotalIncome({ totalPackages, totalAddons });
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const totalAmount = totalIncome.totalPackages + totalIncome.totalAddons;

  const data = useMemo(() => [
    { title: titles[0], value: totalIncome.totalPackages, color: labels[0] },
    { title: titles[1], value: totalIncome.totalAddons, color: labels[1] },
  ], [totalIncome]);

  return (
    <DoughnutChart
      title="Total income"
      data={data}
      totalAmount={totalAmount}
      fetchData={fetchIncomes}
    />
  );
};

export default React.memo(TotalIncome);
