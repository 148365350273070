import React from 'react';
import ContentLoader from 'react-content-loader';

import styled from '@emotion/styled';

const tableColumns = [
  { key: '+', text: '+' },
  { key: 'date', text: 'Date' },
  { key: 'name', text: 'Name' },
  { key: 'amount', text: 'Amount' },
  { key: 'commisson', text: 'Commisson' },
  { key: 'payer', text: 'Payer' },
  { key: 'receipt_number', text: 'Receipt Number' },
];

const TableSkeleton = () => {
  return (
    <StyledSkeletonTable>
      <thead>
        <tr>
          {tableColumns.map((column, idx) => (
            <th key={idx}>{column.text}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Array(5)
          .fill(undefined)
          .map((_, idx) => (
            <tr key={idx}>
              {tableColumns.map((_, idx) => (
                <td key={idx}>
                  {idx ? (
                    <ContentLoader speed={2} height="56" width="124">
                      <rect
                        x="0"
                        y="21"
                        width={idx < tableColumns.length - 1 ? '80' : '124'}
                        height="12"
                        rx="3"
                      />
                    </ContentLoader>
                  ) :
                    (
                      <ContentLoader speed={2} height="56" width="24">
                        <rect x="0" width="12" height="12" y="22" rx="3" />
                      </ContentLoader>
                    )
                  }
                </td>
              ))}
            </tr>
          ))}
      </tbody>
    </StyledSkeletonTable>
  );
};

export default TableSkeleton;

const StyledSkeletonTable = styled.table`
  width: 100%;
  margin: 24px 0;
  border-collapse: collapse;

  caption {
    font-weight: bold;
    font-size: 16px;
    line-height: 32px;
    text-align: left;
  }
  tr {
    border-bottom: 1px solid ${props => props.theme.colors.seashell};
  }
  th {
    color: ${props => props.theme.colors.grayDark};
    position: relative;
    text-align: left;
    padding: 16px 5px;
    font-size: 14px;
    font-weight: normal;
    line-height: 16px;
    white-space: nowrap;
  }
`;
