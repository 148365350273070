import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Button, Dropdown, DropdownOption, Icon, Input } from 'components';
import useLayout from 'hooks/useLayout';
import { TransactionDirection } from './transactions.d';
import TransactionsContext from './context';

const directionOptions: DropdownOption<TransactionDirection>[] = [
  { value: 'incomes', text: 'Income' },
  { value: 'expenses', text: 'Expense' },
  { value: 'refunds', text: 'Refunds' },
  { value: 'all', text: 'All' }
];

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TransactionsFiltersProps { }

const TransactionsFilters: React.FC<TransactionsFiltersProps> = () => {
  const {
    filterSearch,
    setFilterSearch,
    filterDirection,
    setFilterDirection,
    exportAsCSV,
    setRequestPaymentModalIsOpen
  } = useContext(TransactionsContext);
  const layout = useLayout();

  return (
    <StyledTransactionsFilters className="transactions-filters">
      <h2 className="title">Transactions</h2>
      <div className="filters-controls">
        <Input
          value={filterSearch}
          onChange={e => setFilterSearch(e.target.value)}
          icon="search"
          iconPosition="left"
          placeholder="Search"
        />
        <Dropdown
          options={directionOptions}
          value={filterDirection}
          onChange={setFilterDirection}
          buttonLike
          label="Direction:"
        />
        {layout === 'mobile' ? (
          <>
            <Button secondary onClick={exportAsCSV}>
              <Icon name="download" size={12} />
            </Button>
            <Button onClick={() => setRequestPaymentModalIsOpen(true)}>+</Button>
          </>
        ) : (
            <>
              <Button secondary onClick={exportAsCSV}>
                <Icon name="download" size={12} /> Export as .csv
              </Button>
              <Button onClick={() => setRequestPaymentModalIsOpen(true)}>+ Request Payment</Button>
            </>
          )
        }
      </div >
    </StyledTransactionsFilters >
  );
};

export default TransactionsFilters;

const StyledTransactionsFilters = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 24px 0;

  .filters-controls {
    display: flex;

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      flex-wrap: wrap;
      width: 100%;

      .input {
        width: 100%;
        margin: 0 0 12px 0;
      }

      .button {
        width: 32px;
        margin-left: auto;
        font-size: 20px;
      }
    }
  }

  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-right: auto;
  }

  .input {
    width: 300px;
    height: 32px;
  }

  .input-wrapper {
    .input-component {
      height: 32px !important;
    }
  }

  .button {
    white-space: nowrap;

    &:last-of-type {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .input,
  .dropdown,
  .button {
    margin-right: 12px;
  }
`;
