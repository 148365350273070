import React, { useContext, useMemo, useState } from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';

import { Button, Modal, Tabs } from '@xchange/uikit';
import RequestPaymentForm from 'components/RequestPaymentForm';
import MainLayout from 'components/MainLayout';
import Pagination from 'components/Pagination';
import useLayout from 'hooks/useLayout';
import BarChart from './BarChart';
import TotalIncome from './TotalIncome';
import TotalExpense from './TotalExpense';
import Context, { TransactionsContextProvider } from './context';
import TransactionsFilters from './TransactionsFilters';
import TransactionsList from './TransactionsList';
import TransactionsListItem from './TransactionsListItem';
import TransactionsListItemMobile from './TransactionsListItemMobile';
import TableSkeleton from './TableSkeleton';
import TableSkeletonMobile from './TableSkeletonMobile';
import RefundForm from './RefundForm';

const TransactionsPage = () => {
  const layout = useLayout();
  const {
    transactions,
    setTransactions,
    refundTransaction,
    page,
    setPage,
    totalPages,
    loading,
    requestPaymentModalIsOpen,
    setRequestPaymentModalIsOpen
  } = useContext(Context);
  const [refundModalId, setRefundModalId] = useState<string>();
  const [showCharts, setShowCharts] = useState<boolean>(true);
  const refundModalIsOpen = Boolean(refundModalId);
  const [activeTab, setActiveTab] = useState<'graphs' | 'list'>('graphs');
  const refundData = useMemo(
    () =>
      refundModalIsOpen
        ? transactions.find(transaction => transaction.id === refundModalId)
        : undefined,
    [refundModalId, refundModalIsOpen, transactions]
  );
  const TransactionListComponent = useMemo(
    () => (layout === 'mobile' ? TransactionsListItemMobile : TransactionsListItem),
    [layout]
  );
  const SkeletonComponent = useMemo(
    () => (layout === 'mobile' ? TableSkeletonMobile : TableSkeleton),
    [layout]
  );

  return (
    <MainLayout>
      <StyledTransactionsPage className="transactions">
        {layout === 'mobile' && (
          <>
            <h1 className="title">Transactions</h1>
            <Tabs
              activeTab={activeTab}
              items={[
                { key: 'graphs', text: 'Graphs' },
                { key: 'list', text: 'List' }
              ]}
              onSelect={(_e, item) => setActiveTab(item.key as any)}
            />
          </>
        )}
        {layout !== 'mobile' && (
          <div className="toggle-header">
            <h1 className="title">Analytics</h1>
            <Button simple className="toggle-header__btn" onClick={() => setShowCharts(v => !v)}>
              {showCharts ? 'Hide' : 'Show'}
            </Button>
          </div>
        )}
        {(activeTab === 'graphs' || layout !== 'mobile') && (
          <div className={clsx('transactions-charts', { collapsed: !showCharts })}>
            <div className="transactions-chart">
              <BarChart title="Income by day" />
            </div>
            <div className="transactions-chart">
              <TotalIncome />
            </div>
            <div className="transactions-chart">
              <TotalExpense />
            </div>
          </div>
        )}

        {(activeTab === 'list' || layout !== 'mobile') && <TransactionsFilters />}

        {(activeTab === 'list' || layout !== 'mobile') &&
          (loading ? (
            <SkeletonComponent />
          ) : (
              <>
                <TransactionsList>
                  {transactions.map(item => (
                    <TransactionListComponent
                      key={item.id}
                      data={item}
                      onRefundButtonClick={setRefundModalId}
                    />
                  ))}
                </TransactionsList>
                {transactions.length > 0 ? (
                  totalPages > 1 && (
                    <Pagination page={page} totalPages={totalPages} onPageChange={setPage} />
                  )
                ) : (
                    <div className="empty-list">No transactions found.</div>
                  )}
              </>
            ))}
      </StyledTransactionsPage>
      <StyledRefundModal
        open={refundModalIsOpen}
        modalTitle="Refund Transaction"
        onClose={() => setRefundModalId(undefined)}>
        <RefundForm
          refundAmount={Number(refundData?.amount)}
          onCancel={() => setRefundModalId(undefined)}
          onSubmit={refundAmount => {
            refundTransaction(refundModalId!, refundAmount);
            setRefundModalId(undefined);
          }}
        />
      </StyledRefundModal>
      <StyledPaymentModal
        open={requestPaymentModalIsOpen}
        modalTitle="Request Payment"
        onClose={() => setRequestPaymentModalIsOpen(false)}>
        <RequestPaymentForm
          onReturn={() => setRequestPaymentModalIsOpen(false)}
          onSuccess={newTransaction =>
            setTransactions(transactions => [...transactions, newTransaction])
          }
        />
      </StyledPaymentModal>
    </MainLayout>
  );
};

export default props => (
  <TransactionsContextProvider>
    <TransactionsPage {...props} />
  </TransactionsContextProvider>
);

const StyledTransactionsPage = styled.div`
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
  }

  .tabs {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 32px;

    .tab-item {
      width: 100%;
    }
  }

  .toggle-header {
    display: inline-flex;
    &__btn {
      margin-left: 24px;
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
      color: ${props => props.theme.colors.red};
    }
  }

  .transactions {
    &-charts {
      height: 318px;
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      grid-gap: 20px;
      overflow: auto;
      transition: height 0.2s ease-out;

      &.collapsed {
        height: 0;
      }

      @media (max-width: ${props => props.theme.breakpoints.sm}) {
        height: auto;
        grid-template-columns: 1fr;
      }
    }
    &-chart {
      padding: 8px;
      border: 1px solid ${props => props.theme.colors.graphite};
      border-radius: 6px;
    }
  }

  .empty-list {
    margin: 12px 0;
    font-size: 14px;
    color: ${props => props.theme.colors.grayDark};
  }

  .pagination {
    display: flex;
    justify-content: center;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .transactions-filters {
      margin-top: 24px;
      .title {
        display: none !important;
      }
    }
  }
`;

const StyledRefundModal = styled(Modal)`
  .modal-content {
    max-width: 324px;
  }
  .modal-body {
    .button {
      width: 100%;
      height: 40px;

      &:last-of-type {
        margin-top: 16px;
      }
    }
  }
`;

const StyledPaymentModal = styled(Modal)`
  .header-section {
    display: none;
  }

  .form-grid {
    grid-template-columns: 1fr !important;

    .two-wide {
      grid-column: 1 !important;
    }
  }

  form {
    .form-controls {
      flex-direction: column;

      .button {
        margin-left: 0;
        margin-top: 8px;
        width: 100%;
      }
    }
  }
`;
