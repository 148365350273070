import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import styled from '@emotion/styled';

import { Button, RadioButton } from '@xchange/uikit';
import { InputCurrency } from 'components';
import { formatCurrency } from 'utils/rifmFormatters';

interface RefundFormProps extends Omit<React.HtmlHTMLAttributes<HTMLElement>, 'onSubmit'> {
  refundAmount: number;
  onCancel: () => void;
  onSubmit: (value: number) => void;
}

const RefundForm: React.FC<RefundFormProps> = ({ refundAmount, onCancel, onSubmit }) => {
  const {
    register,
    watch,
    control,
    errors,
    setValue,
    clearErrors,
    handleSubmit,
    formState
  } = useForm({
    defaultValues: { refundAmount, fullAmount: '1' },
    mode: 'onChange',
    reValidateMode: 'onChange'
  });
  const fullAmount = watch('fullAmount');
  const makeFullRefund = !!Number(fullAmount);

  const validateAmount = (value: number) => {
    if (makeFullRefund) return true;
    return value <= refundAmount || `Refund amount cannot be greater than $${refundAmount}`;
  };

  const submit = ({ refundAmount }) => onSubmit(refundAmount);

  useEffect(() => {
    if (makeFullRefund) {
      setValue('refundAmount', refundAmount);
      clearErrors('refundAmount');
    }
  }, [makeFullRefund, clearErrors, setValue, refundAmount]);

  return (
    <StyledRefundForm className="refund-form">
      <div className="refund-form__row">
        <RadioButton
          {...register("fullAmount")}
          value="1"
          defaultChecked
          label={
            <>
              Full amount <strong>{formatCurrency(refundAmount)}</strong>
            </>
          }
        />
        <br />
        <RadioButton {...register("fullAmount")} value="0" label="Other" />
      </div>
      <div className="refund-form__row">
        <Controller
          control={control}
          name="refundAmount"
          rules={{
            required: makeFullRefund,
            validate: validateAmount
          }}
          render={({ field, formState: { errors } }) => (
            <InputCurrency
              label="Amount"
              placeholder="Enter"
              error={errors.refundAmount?.message}
              disabled={makeFullRefund}
              {...field}
            />
          )}
        />
      </div>
      <Button
        secondary
        onClick={e => {
          e.preventDefault();
          onCancel();
        }}>
        Cancel
      </Button>
      <Button
        onClick={handleSubmit(submit)}
        disabled={!formState.isValid || formState.isSubmitting}>
        Refund
      </Button>
    </StyledRefundForm>
  );
};

export default React.memo(RefundForm);

const StyledRefundForm = styled.form`
  .radiobutton ~ .radiobutton {
    margin-top: 8px;
  }

  .input {
    width: 100%;
  }

  .refund-form {
    &__row {
      margin: 0 0 16px;
    }
  }
`;
