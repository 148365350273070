import React, { useState, useMemo, useEffect } from 'react';
import dayjs from 'dayjs';
import styled from '@emotion/styled/macro';
import { PieChart } from 'react-minimal-pie-chart';
import ReactTooltip from 'react-tooltip';

import { colors } from 'styles/theme.json';
import { formatCurrency } from 'utils/rifmFormatters';
import { Dropdown } from 'components';

const dropdownOptions = [
  { value: 'current_week', text: 'Week-to-Date' },
  { value: 'last_week', text: 'Last Week' },
  { value: 'current_month', text: 'Month-to-Date' },
  { value: 'last_month', text: 'Last Month' },
];
interface DoughnutChartProps {
  title: string;
  data: Array<{
    title: string;
    color: string;
    value: number;
  }>;
  totalAmount: number;
  fetchData: (values: { startDate: number, endDate: number; }) => void;
}

const DoughnutChart: React.FC<DoughnutChartProps> = ({ title, data, totalAmount, fetchData }) => {
  const [dateRange, setDateRange] = useState<string>('current_week');
  const [hovered, setHovered] = useState<number | null>(null);

  const dates: { startDate: number, endDate: number; } = useMemo(() => {
    let from, to = dayjs();

    switch (dateRange) {
      case 'last_week':
        from = dayjs().subtract(1, 'week').startOf('week');
        to = dayjs().startOf('week');
        break;
      case 'current_month':
        from = dayjs().startOf('month');
        break;
      case 'last_month':
        from = dayjs().subtract(1, 'month').startOf('month');
        to = dayjs().startOf('month');
        break;
      default:
        from = dayjs().startOf('week');
    }

    return { startDate: from.unix(), endDate: to.unix() };
  }, [dateRange]);

  useEffect(() => {
    fetchData(dates);
  }, [dates]);

  return (
    <StyledDoughnutChart className="bar-chart">
      <div className="bar-chart__header">
        <h3>{title}</h3>
        <Dropdown
          options={dropdownOptions}
          value={dateRange}
          onChange={setDateRange}
          buttonLike
          className="bar-chart__select"
          data-cy="dropdown_date_range"
        />
      </div>
      <div className="responsive-container">
        {totalAmount === 0 ? (
          <h3>No data available for this period.</h3>
        ) : (
            <div data-tip="" data-for="chart">
              <PieChart
                data={data}
                viewBoxSize={[100, 100]}
                lineWidth={20}
                totalValue={totalAmount}
                label={(_) => formatCurrency(totalAmount)}
                labelStyle={{
                  fontSize: '14px',
                  fontFamily: 'Inter',
                  fontWeight: 600
                }}
                labelPosition={0}
                lengthAngle={-360}
                animate
                onMouseOver={(_, index) => {
                  setHovered(index);
                }}
                onMouseOut={() => {
                  setHovered(null);
                }}
              />
              <ReactTooltip
                id="chart"
                getContent={() =>
                  typeof hovered === 'number' ? `${data[hovered].title}: ${formatCurrency(data[hovered].value)}` : null
                }
              />
            </div>
          )}
      </div>
      {totalAmount > 0 && (
        <StyledChartLegend className="chart-legend">
          {data.map(item => (
            <StyledChartLegendLabel
              className="chart-legend-label"
              key={item.title}
            >
              <span className="chart-legend-label-box" style={{ background: item.color }} />
              {item.title}
            </StyledChartLegendLabel>
          ))}
        </StyledChartLegend>
      )}
    </StyledDoughnutChart>
  );
};

export default React.memo(DoughnutChart);

const StyledDoughnutChart = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .bar-chart {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__select {
      .dropdown-button {
        border: none;
      }
      .dropdown-text {
        font-size: 14px;
        font-weight: normal;
        line-height: 24px;
        color: ${colors.red};
      }
      .dropdown-options {
        width: max-content;
      }
      .icon-chevron {
        fill: ${colors.red};
      }
    }
  }

  .responsive-container {
    position: relative;
    height: 148px;
    width: 100%; 

    div[data-tip] {
      height: 100%;
    }

    h3 {
      margin: auto;
      color: ${colors.grayDark};
      top: -16px;
      position: relative;
      width: 100%;
      padding: 0 70px;
      text-align: center;
    }

    &.vertical {
      height: 563px;
    }
  }

  h3 {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }
`;

export const StyledChartLegend = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-evenly;

  &.chart-legend-label {
    margin-top: 36px;

    &:last-child {
      padding-right: 0;
    }
  }
`;

export const StyledChartLegendLabel = styled.span`
  margin: 0 0 0 8px;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  &:last-child {
    margin-right: 0;
  }

  & .chart-legend-label-box {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 5px;
    flex-shrink: 0;
  }
`;
