import React from 'react';
import { Router } from '@reach/router';
import PrivateRoute from 'components/PrivateRoute';
import TransactionsPage from 'views/TransactionsPage';
import Workspace from 'workspace';

const Transactions = () => {
  return (
    <Workspace>
      <Router>
        <PrivateRoute path="/transactions" component={TransactionsPage} admin />
      </Router>
    </Workspace>
  );
};

export default Transactions;
