import React from 'react';
import ContentLoader from 'react-content-loader';

import styled from '@emotion/styled';

const tableColumns = [
  { key: 'date', text: 'Date' },
  { key: 'name', text: 'Name' },
  { key: 'amount', text: 'Amount' },
  { key: 'commisson', text: 'Commisson' },
  { key: 'payer', text: 'Payer' },
  { key: 'receipt_number', text: 'Receipt Number' },
];

const TableSkeletonMobile = () => {
  return (
    <StyledSkeletonTable>
      <tbody>
        {tableColumns.map((_, idx) => (
          <tr key={idx}>
            <td key={idx}>
              <ContentLoader speed={2} height="56" width="100%">
                <rect
                  x="0"
                  y="21"
                  width="80"
                  height="12"
                  rx="3"
                />
                <rect
                  x="124"
                  y="21"
                  width="100%"
                  height="12"
                  rx="3"
                />
              </ContentLoader>
            </td>
          </tr>
        ))}
      </tbody>
    </StyledSkeletonTable>
  );
};

export default TableSkeletonMobile;

const StyledSkeletonTable = styled.table`
  width: 100%;
  margin: 24px 0;
  border-collapse: collapse;

  caption {
    font-weight: bold;
    font-size: 16px;
    line-height: 32px;
    text-align: left;
  }
  tr {
    border-bottom: 1px solid ${props => props.theme.colors.seashell};
  }
`;
