import styled from '@emotion/styled';
import React, { useContext } from 'react';
import clsx from 'clsx';

import { Button, Icon } from '@xchange/uikit';
import Context from './context';
import { TransactionsSortBy } from './transactions.d';

const columns: { text: string; sortBy?: TransactionsSortBy; }[] = [
  {
    text: '+'
  },
  {
    text: 'Date',
    sortBy: 'created_on'
  },
  {
    text: 'Name',
    sortBy: 'name'
  },
  {
    text: 'Amount',
    sortBy: 'amount'
  },
  {
    text: 'Commisson',
    sortBy: 'commission'
  },
  {
    text: 'Payer',
    sortBy: 'payer'
  },
  {
    text: 'Receipt Number',
    sortBy: 'receipt_number'
  }
];

const TransactionsList: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children }) => {
  const { filterSortBy, sortOrder, handleOrderTransactions } = useContext(Context);

  return (
    <StyledTransactionsList>
      <div className="list-inner">
        <div className="list-header">
          {columns.map(column => (
            <div key={column.text} className="list-header__item">
              {column.sortBy ? (
                <Button
                  simple
                  className={clsx("sort-btn", sortOrder)}
                  onClick={() => handleOrderTransactions(column.sortBy!)}
                >
                  {column.text}
                  {column.sortBy === filterSortBy && <Icon name="arrow" />}
                </Button>
              ) : column.text}
            </div>
          ))}
        </div>
        {children}
      </div>
    </StyledTransactionsList>
  );
};

export default TransactionsList;

const StyledTransactionsList = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 16px;
  position: relative;
  overflow: auto;
  /* max-height: 800px; */

  .status {
    background: ${props => props.theme.colors.seashell};
    color: ${props => props.theme.colors.red};
    border-radius: ${props => props.theme.misc.borderRadius};
    padding: 0 4px;
    font-size: 12px;
    line-height: 16px;
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      display: inline-block;
      margin-top: 4px;
    }
  }

  .list-header {
    display: grid;
    grid-gap: 32px;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-template-columns: 10px 1fr 2fr 0.5fr 0.5fr 1fr 1fr;
    border-bottom: 1px solid ${props => props.theme.colors.seashell};
    background: white;
    // set list max-height to make it work
    position: sticky;
    top: 0;

    &__item {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      font-size: 14px;
      line-height: 16px;
      color: ${props => props.theme.colors.grayDark};
      padding: 12px 0;
      .button {
        font-size: inherit;
        color: inherit;
        font-weight: inherit;
      }
      .icon-arrow {
        margin-left: 5px;
        cursor: pointer;
        fill: ${props => props.theme.colors.red};
      }
      .sort-btn {
        &:focus {
          opacity: 1;
        }
        &.desc {
          .icon-arrow {
            transform: rotate(-90deg);
          }
        }
        &.asc {
          .icon-arrow {
            transform: rotate(90deg);
          }
        }
      }
    }

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      display: none;
    }
  }
`;
