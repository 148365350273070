import React, { useState, useMemo } from 'react';
import notify from 'notify';

import { tryGetFirstError } from 'utils/requests';
import { getExpenses } from 'api/billing';
import DoughnutChart from './DoughnutChart';

const labels = ['#DF1721', '#FF7272', '#FFE3E5'];
const titles = ['Site Fee', 'Users Fee', 'Closed Deals'];

const TotalExpense = () => {
  const [totalExpenses, setTotalExpenses] = useState<{
    totalSiteMonthlyFee: number;
    totalUserFee: number;
    totalClosedDealFee: number;
  }>({
    totalSiteMonthlyFee: 0,
    totalUserFee: 0,
    totalClosedDealFee: 0
  });

  const fetchExpenses = async (dates) => {
    try {
      const { totalSiteMonthlyFee, totalUserFee, totalClosedDealFee } = await getExpenses(dates);
      setTotalExpenses({ totalSiteMonthlyFee, totalUserFee, totalClosedDealFee });
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const totalAmount = totalExpenses.totalSiteMonthlyFee + totalExpenses.totalUserFee + totalExpenses.totalClosedDealFee;

  const data = useMemo(() => [
    { title: titles[0], value: totalExpenses.totalSiteMonthlyFee, color: labels[0] },
    { title: titles[1], value: totalExpenses.totalUserFee, color: labels[1] },
    { title: titles[2], value: totalExpenses.totalClosedDealFee, color: labels[2] },
  ], [totalExpenses]);

  return (
    <DoughnutChart
      title="Total spent"
      data={data}
      totalAmount={totalAmount}
      fetchData={fetchExpenses}
    />
  );
};

export default React.memo(TotalExpense);
