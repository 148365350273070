import React, { useState, useCallback, useEffect } from 'react';
import notify from 'notify';
import { useDebouncedCallback } from 'use-debounce/lib';
import { tryGetFirstError } from 'utils/requests';
import useDidUpdate from 'hooks/useDidUpdate';
import {
  getTransactions,
  getTransactionsCSV,
  refundTransaction as refundTransactionRequest
} from 'api/billing';
import {
  GetTransactionsParams,
  Transaction,
  TransactionsSortBy,
  TransactionDirection
} from './transactions.d';

interface TransactionsContextValues {
  transactions: Transaction[];
  setTransactions: React.Dispatch<React.SetStateAction<Transaction[]>>;
  exportAsCSV: () => void;
  filterSearch: string;
  setFilterSearch: React.Dispatch<React.SetStateAction<string>>;
  filterSortBy?: TransactionsSortBy;
  setFilterSortBy: React.Dispatch<React.SetStateAction<TransactionsSortBy | undefined>>;
  filterDirection: TransactionDirection;
  setFilterDirection: React.Dispatch<React.SetStateAction<TransactionDirection>>;
  refundTransaction: (id: string, amount: number) => void;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  sortOrder?: 'asc' | 'desc';
  handleOrderTransactions: (value: TransactionsSortBy) => void;
  totalPages: number;
  loading: boolean;
  requestPaymentModalIsOpen: boolean;
  setRequestPaymentModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const TransactionsContext = React.createContext({} as TransactionsContextValues);

export const TransactionsContextProvider = ({ children }) => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterSortBy, setFilterSortBy] = useState<TransactionsSortBy>();
  const [filterDirection, setFilterDirection] = useState<TransactionDirection>('incomes');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [requestPaymentModalIsOpen, setRequestPaymentModalIsOpen] = useState(false);

  const exportAsCSV = async () => {
    try {
      const csv = await getTransactionsCSV({
        type: filterDirection,
        offset: page,
        search: filterSearch,
        sortBy: filterSortBy,
        sortOrder
      });
      const link = document.createElement('a');
      link.href = `data:text/csv;charset=utf-16,${encodeURIComponent(csv)}`;
      link.target = '_blank';
      link.download = 'transactions.csv';
      link.click();
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const refundTransaction = async (id: string, refundAmount: number) => {
    try {
      await refundTransactionRequest(id, refundAmount);
      setTransactions(transactions =>
        transactions.map(transaction =>
          transaction.id === id ? { ...transaction, type: 'refunded' } : transaction
        )
      );
      fetchTransactions({
        type: filterDirection,
        offset: page,
        search: filterSearch,
        sortBy: filterSortBy,
        sortOrder
      });
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const handleOrderTransactions = key => {
    // cycle sort: descending, ascending, no sort
    setFilterSortBy(tableSort => {
      if (tableSort === key) {
        setSortOrder(sortDirection => {
          setFilterSortBy(sortDirection !== 'asc' ? key : undefined);
          return sortDirection === 'desc' ? 'asc' : 'desc';
        });
        return;
      }
      setSortOrder('desc');
      return key;
    });
  };

  const fetchTransactions = useCallback(async (params: GetTransactionsParams) => {
    setLoading(true);
    try {
      const { transactions, totalPages } = await getTransactions(params);
      setTransactions(transactions);
      setTotalPages(totalPages);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
    setLoading(false);
  }, []);

  const [fetchTransactionsDebounced] = useDebouncedCallback(fetchTransactions, 300);

  useEffect(() => {
    fetchTransactions({
      type: filterDirection,
      offset: page,
      search: filterSearch,
      sortBy: filterSortBy,
      sortOrder
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchTransactions]);

  useDidUpdate(() => {
    fetchTransactionsDebounced({
      type: filterDirection,
      offset: page,
      search: filterSearch,
      sortBy: filterSortBy,
      sortOrder
    });
  }, [fetchTransactionsDebounced, filterDirection, filterSearch, filterSortBy, sortOrder, page]);

  const values: TransactionsContextValues = {
    transactions,
    setTransactions,
    exportAsCSV,
    filterSearch,
    setFilterSearch,
    filterSortBy,
    setFilterSortBy,
    filterDirection,
    setFilterDirection,
    refundTransaction,
    page,
    setPage,
    totalPages,
    sortOrder,
    handleOrderTransactions,
    loading,
    requestPaymentModalIsOpen,
    setRequestPaymentModalIsOpen
  };

  return <TransactionsContext.Provider value={values}>{children}</TransactionsContext.Provider>;
};

export default TransactionsContext;
